import React, { useContext, useState } from 'react';
import { Redirect, useLocation, Link } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import {
  PrimaryStretchedButton,
  I18n,
  CircleLoader,
  ToastNotification,
} from 'design-system';

import PortalAPI from 'utilities/PortalAPI';

import SignInLayout from './SignInLayout';
import ErrorAlert from './ErrorAlert';

import { UserContext } from 'components/contexts/UserContext';


const VerifyAccountError = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const { token, errorMessage, email } = location.state || {};

  const [state, setState] = useState({
    isLoading: false,
    errorAlertMessage: null,
    notifications: [],
    userAlreadyConfirmed: false,
  });

  async function resendVerificationEmail() {
    setState({
      ...state,
      errorAlertMessage: null,
      isLoading: true,
    });
    const data = await PortalAPI.post('auth/resend_confirmation_code', {
      token,
      email,
      customer_id: userContext.customerId,
    });
    let notifications = state.notifications;
    let errorAlertMessage;
    let userAlreadyConfirmed;
    if (data.success) {
      notifications = notifications.concat({
        id: uuidv4(),
        heading: I18n.t('email_sent'),
        message: I18n.t('check_your_inbox_we_just_resent_your_password_reset_link'),
      });
    } else if (data.error && data.error.message === 'User is already confirmed.') {
      userAlreadyConfirmed = true;
    } else if (data.error && data.error.code === 'LimitExceededException') {
      errorAlertMessage = I18n.t('attempt_limit_exceeded_please_try_after_some_time');
    } else {
      errorAlertMessage = I18n.t('unexpected_error');
    }
    setState({
      ...state,
      isLoading: false,
      notifications,
      errorAlertMessage,
      userAlreadyConfirmed,
    });
  }

  if (state.userAlreadyConfirmed) {
    return <Redirect push to={'/verify-account-success'} />;
  }

  return (
    <SignInLayout pageHeader={I18n.t('account_verification')}>
      <h2 className='font-size--medium m-vertical--4'>
        {I18n.t('unable_to_verify')}
      </h2>

      <p className='m-bottom--28 color--grey600'>
        {errorMessage}
      </p>

      <div className='textAlign--center'>
        <PrimaryStretchedButton className='m-bottom--16' onClick={resendVerificationEmail}>
          {I18n.t('resend_verification_email')}
        </PrimaryStretchedButton>
      </div>

      {state.isLoading &&
        <div className='l-flex l-flex--hAlignCenter'>
          <CircleLoader />
        </div>
      }

      {state.errorAlertMessage &&
        <ErrorAlert>
          {state.errorAlertMessage}
        </ErrorAlert>
      }

      <Link to='/' className='font-size--small'>
        {I18n.t('back_to_log_in')}
      </Link>

      <ToastNotification notifications={state.notifications} />
    </SignInLayout>
  );
};

export default VerifyAccountError;
