import React from 'react';

import CreateAccountConfirmation from '../components/views/SignIn/CreateAccountConfirmation';


const Debug = () => {
  return (
    <CreateAccountConfirmation />
  );
};

export default Debug;