import React, { useContext, useState, useEffect } from 'react';

import {
  RadioGroup,
  Radio,
  Alert,
  Toggle,
  I18n,
} from 'design-system';

import PortalAPI from 'utilities/PortalAPI';

import { UserContext } from 'components/contexts/UserContext';
import styles from './PauseInternet.module.scss';
import { format, isPast } from 'date-fns';


const timeHelpers = {
  one_hour: () => 60 * 60 * 1000,
  three_hours: () => 3 * 60 * 60 * 1000,
  tomorrow: () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(6, 0, 0, 0);
    return tomorrow.getTime() - now.getTime();
  },
};

const PauseInternet = () => {
  const userContext = useContext(UserContext);
  const currentStudent = userContext.currentStudent;
  const [show, setShow] = useState(false);
  const [selectedPause, setSelectedPause] = useState();
  const [untilTime, setUntilTime] = useState();

  async function pauseInternet(pauseUntil) {
    setUntilTime(pauseUntil);
    const customerId = userContext.user.customer_id;
    await PortalAPI.put(`students/${customerId}/pause_internet_access`, {
      token: currentStudent.pause_internet_token,
      pause_until: pauseUntil,
    });
    currentStudent.pause_until = pauseUntil;
  }

  const changeHandler = (value) => {
    const pauseUntil = new Date().getTime() + timeHelpers[value]();
    pauseInternet(pauseUntil);
    setSelectedPause(value);
  };

  const clickHandler = (checked) => {
    if (!checked) {
      const currentPauseUntil = currentStudent && currentStudent.pause_until;
      if (currentPauseUntil && !isPast(currentPauseUntil)) {
        // anything in the past turns off the feature
        pauseInternet(1);
      }
      setSelectedPause(undefined);
    }
    setShow(checked);
  };

  useEffect(() => {
    const pauseUntil = currentStudent && currentStudent.pause_until;
    setUntilTime(pauseUntil);
    setShow(Boolean(pauseUntil) && !isPast(pauseUntil));
    setSelectedPause(undefined);
  }, [currentStudent]);

  return (
    <div className={styles.pauseContainer}>
      <Alert className={styles.alertContainer}>
        <Toggle show={show} onClick={(e) => clickHandler(e.target.checked)} />

        <div>
          <strong>{I18n.t('pause_web_browsing')}</strong>
          <p className='m-top--8 m-bottom--12 color--grey600'>
            {I18n.t('switch_on_to_temporary_block_website_browsing_while_not_at_school')}
          </p>

          {show && userContext.students.length && (
            <div className={styles.radioGroup}>
              <RadioGroup
                name='pauseWebBrowsing'
                value={selectedPause}
                onChange={(e) => changeHandler(e.target.value)}
              >
                <Radio value='one_hour'>
                  {I18n.t('for_1_hour')}
                </Radio>
                <Radio value='three_hours'>
                  {I18n.t('for_3_hours')}
                </Radio>
                <Radio value='tomorrow'>
                  {I18n.t('until_tomorrow_morning')}
                </Radio>
              </RadioGroup>

              {untilTime && !isPast(untilTime) && (
                <div className={styles.pauseStatement}>
                  {I18n.t('paused_until_time', { time: format(untilTime, 'h:mm a') })}
                </div>
              )}
            </div>
          )}
        </div>
      </Alert>
    </div>
  );
};

export default PauseInternet;