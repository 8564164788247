import React, { useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import {
  CircleLoader,
  PrimaryStretchedButton,
  TextInput,
  I18n,
} from 'design-system';

import PortalAPI from 'utilities/PortalAPI';

import ErrorAlert from './ErrorAlert';

import SignInLayout from './SignInLayout';


const ForgotPassword = () => {
  const { state } = useLocation();
  const [email, setEmail] = useState(
    (state && state.email) ||
    (localStorage.rememberUser === 'true' && localStorage.userEmail) ||
    ''
  );
  const [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [userIsNotConfirmed, setUserIsNotConfirmed] = useState(false);

  function submitAllowed() {
    return (
      email.trim() &&
      !loading
    );
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter' && submitAllowed()) {
      submitForgotPassword(e);
    }
  }

  async function submitForgotPassword(e) {
    e.preventDefault();
    errorAlertMessage && setErrorAlertMessage(null);
    setLoading(true);
    const data = await PortalAPI.post('auth/forgot_password', {
      email,
    });
    setLoading(false);
    if (data.success) {
      setPasswordResetLinkSent(true);
    } else if (data.error && data.error.code === 'UserNotFoundException') {
      setErrorAlertMessage(I18n.t('no_account_found_for_that_email_address'));
    } else if (data.error && data.error.code === 'LimitExceededException') {
      setErrorAlertMessage(I18n.t('attempt_limit_exceeded_please_try_after_some_time'));
    } else if (data.error && data.error.message === 'Cannot reset password for the user as there is no ' +
        'registered/verified email or phone_number') {
      setUserIsNotConfirmed(true);
    } else {
      setErrorAlertMessage(I18n.t('unexpected_error'));
    }
  }

  if (passwordResetLinkSent) {
    return <Redirect push to={{ pathname: '/password-reset-sent/', state: { email } }} />;
  }

  if (userIsNotConfirmed) {
    return (
      <Redirect
        push to={{
          pathname: '/verify-account-error',
          state: {
            email,
            errorMessage: I18n.t('your_email_address_is_not_verified'),
          },
        }}
      />
    );
  }

  return (
    <SignInLayout>
      <form>
        <h2 className='font-size--medium m-vertical--4'>
          {I18n.t('forgot_password')}
        </h2>
        <p className='color--grey600'>
          {I18n.t('enter_your_email_address_and_well_send_a_link_you')}
        </p>

        <TextInput
          placeholder={I18n.t('email_address')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
          className='m-top--16 m-bottom--24'
        />

        <div className='textAlign--center'>
          <PrimaryStretchedButton
            className='m-bottom--16'
            onClick={submitForgotPassword}
            disabled={!submitAllowed()}
          >
            {I18n.t('send_reset_link')}
          </PrimaryStretchedButton>
        </div>
      </form>

      {loading &&
        <div className='l-flex l-flex--hAlignCenter'>
          <CircleLoader />
        </div>
      }

      {errorAlertMessage &&
        <ErrorAlert className='m-bottom--16'>
          {errorAlertMessage}
        </ErrorAlert>
      }

      <Link to='/' className='font-size--small'>
        {I18n.t('back_to_log_in')}
      </Link>
    </SignInLayout>
  );
};

export default ForgotPassword;
