import React, { useContext, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import {
  CircleLoader,
  EmailSentIcon,
  ToastNotification,
  I18n,
} from 'design-system';

import PortalAPI from 'utilities/PortalAPI';

import SignInLayout from './SignInLayout';
import ErrorAlert from './ErrorAlert';

import { UserContext } from 'components/contexts/UserContext';


const PasswordResetLinkSent = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const email = location.state && location.state.email;
  const [state, setState] = useState({
    notifications: [],
    errorAlertMessage: null,
    isLoading: false,
  });

  async function resendVerificationEmail() {
    setState({
      ...state,
      errorAlertMessage: null,
      isLoading: true,
    });
    const data = await PortalAPI.post('auth/forgot_password', {
      email,
      customer_id: userContext.customerId,
    });
    let notifications = state.notifications;
    let errorAlertMessage;
    if (data.success) {
      notifications = notifications.concat({
        id: uuidv4(),
        heading: I18n.t('email_sent'),
        message: I18n.t('check_your_inbox_we_just_resent_your_password_reset_link'),
      });
    } else if (data.error && data.error.code === 'LimitExceededException') {
      errorAlertMessage = I18n.t('attempt_limit_exceeded_please_try_after_some_time');
    } else {
      errorAlertMessage = I18n.t('unexpected_error');
    }

    setState({
      ...state,
      isLoading: false,
      notifications,
      errorAlertMessage,
    });
  }

  if (!email) {
    return <Redirect to='/' />;
  }

  return (
    <SignInLayout>
      <EmailSentIcon className='icon-email-sent' />
      <h2 className='font-size--medium m-top--20 m-bottom--8'>
        {I18n.t('check_your_email')}
      </h2>

      <p className='color--grey600'>
        {I18n.t('we_just_sent_a_password_reset_link_to_your_email')}
      </p>

      <p className='m-top--28 m-bottom--20 font-size--small color--grey600'>
        {I18n.t('dont_see_the_email_click_here_to_resend_it', { action: resendVerificationEmail })}
      </p>


      {state.isLoading &&
        <div className='l-flex l-flex--hAlignCenter'>
          <CircleLoader />
        </div>
      }

      {state.errorAlertMessage &&
        <ErrorAlert className='m-bottom--20'>
          {state.errorAlertMessage}
        </ErrorAlert>
      }

      <Link to='/' className='font-size--small'>
        {I18n.t('back_to_log_in')}
      </Link>

      <ToastNotification notifications={state.notifications} />
    </SignInLayout>
  );
};

export default PasswordResetLinkSent;
