import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  isToday,
  isYesterday,
  eachDayOfInterval,
  subDays,
  startOfWeek,
  endOfDay,
  isSameDay,
} from 'date-fns';

import {
  Favicon,
  GroupedTable,
  I18n,
} from 'design-system';

import { ReactComponent as EmptyDrawer } from 'images/icon-drawer-empty.svg';


function weekList() {
  // Construct a week list going from Sunday - Saturday.
  const now = new Date();
  const end = subDays(startOfWeek(now), 1); // Set to last Saturday
  const start = subDays(end, 6); // Get Sunday before the end time
  return eachDayOfInterval({ start: start, end: end }).map((datetime) => {
    const dayEnd = endOfDay(datetime);
    return {
      dateLabel: format(dayEnd, 'iiii'),
      at: dayEnd,
    };
  });
}

function processData(results, timeZone, currentPage, hasNextPage) {
  if (!results || results.length < 1) {
    return [];
  }

  // Make sure epoch times are integers and not strings
  results.forEach((item) => {
    item.at = parseInt(item.at, 10);
    item.dateLabel = format(item.at, 'iiii');
  });

  // Instantiate week list
  let pastDays = weekList();

  // On subsequent pages, do not show previous empty days.
  if (currentPage > 1) {
    pastDays = pastDays.filter((dayOfWeek) => dayOfWeek.at > results[0].at);
  }

  // If there is a next page, do not show subsequent weekdays
  if (hasNextPage) {
    const lastResult = results[results.length - 1].at;
    pastDays = pastDays.filter((dayOfWeek) => {
      if (isSameDay(dayOfWeek.at, lastResult)) {
        return true;
      }
      return dayOfWeek.at < lastResult;
    });
  }

  // Find items with that date.
  const formatted = pastDays.map((dayOfWeek) => {
    // Find all matches for the current date
    // then format the matches into Table columns.
    const matches = results.filter((item) => item.dateLabel === dayOfWeek.dateLabel).map((item) => {
      // construct the URL.
      let url = item.path ? item.host + item.path : item.host;
      if (item.query) {
        url = url + item.query;
      }
      //JSX-ify the URL.
      const firstCol = (
        <div className='l-flex l-flex--vAlignCenter' key={url}>
          <Favicon siteUrl={item.host} className='m-right--16' />
          <a href={'http://' + url} className='truncate'>{url}</a>
        </div>
      );
      // Format the time
      let formattedTime;
      if (timeZone) {
        const zonedDate = utcToZonedTime(new Date(item.at), timeZone);
        formattedTime = format(zonedDate, 'h:mm a', { timeZone });
      } else {
        formattedTime = format(item.at, 'h:mm a');
      }
      const secondCol = <span className='nowrap'>{formattedTime}</span>;
      // returning 2 columns, URL and Time
      return [firstCol, secondCol];
    });

    // Construct the Label per group
    let dateLabel = dayOfWeek.dateLabel;
    if (isToday(dayOfWeek.at)) {
      dateLabel = I18n.t('today');
    } else if (isYesterday(dayOfWeek.at)) {
      dateLabel = I18n.t('yesterday');
    }
    // Get item count string
    // Singularize for 1
    let countLabel = I18n.t('count_items', { count: matches.length });
    if (matches.length === 1) {
      countLabel = I18n.t('count_item', { count: matches.length });
    }

    dateLabel = (
      <Fragment>
        {dateLabel}: <strong>{countLabel}</strong>
      </Fragment>
    );

    return {
      label: dateLabel,
      items: matches,
    };
  });

  return formatted;
}

const ActivityTable = ({
  pages,
  currentPage,
  timeZone,
  hasNextPage,
}) => {
  const current = pages.find((item) => currentPage === item.page);

  // Results start out as an empty view.
  let results = [{
    key: 'emptyInternetActivity',
    label: (
      <Fragment>
        {I18n.t('today')}: <strong>{I18n.t('count_items', { count: 0 })}</strong>
      </Fragment>
    ),
    customRow: (
      <tr>
        <td colSpan='2' className='border--0'>
          <div className='l-flex l-flex--column l-flex--vAlignCenter l-flex--hAlignCenter'>
            <EmptyDrawer className='icon-drawer m-top--32' />
            <strong className='m-top--12 m-bottom--4'>{I18n.t('no_data_to_display')}</strong>
            <p className='color--grey600'>
              {I18n.t('check_back_later_after_your_student_has_browsed_the_web')}
            </p>
          </div>
        </td>
      </tr>
    ),
  }];

  // If there are any results, process them.
  if (pages[0] && pages[0].results.length >= 0) {
    results = processData(current.results, timeZone, currentPage, hasNextPage);
  }

  return (
    <GroupedTable
      columnHeaders={[
        I18n.t('address'),
        {
          label: I18n.t('time'),
          className: 'width--100px',
        },
      ]}
      columnData={results}
    />
  );
};

ActivityTable.propTypes = {
  pages: PropTypes.array,
  hasNextPage: PropTypes.bool,
  currentPage: PropTypes.number,
  timeZone: PropTypes.string,
};

export default ActivityTable;
