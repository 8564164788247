import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { I18n } from 'design-system';

import PortalAPI from 'utilities/PortalAPI';

import SignInLayout from './SignInLayout';


const VerifyAccount = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const [accountVerified, setAccountVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    async function attemptToVerifyAccount() {
      const data = await PortalAPI.post('auth/confirm_account', {
        token,
        confirmation_code: queryParams.get('confirmation_code'),
      });
      if (data.success) {
        setAccountVerified(true);
      } else if (data.error && data.error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
        setAccountVerified(true);
      } else if (data.error && data.error.code === 'ExpiredCodeException') {
        setErrorMessage(I18n.t('email_verification_link_expired'));
      } else if (data.error && data.error.code === 'CodeMismatchException') {
        setErrorMessage(I18n.t('email_verification_link_invalid'));
      } else {
        setErrorMessage(I18n.t('unexpected_error'));
      }
    }
    attemptToVerifyAccount();
  }, [queryParams, token]);

  if (accountVerified) {
    return <Redirect push to={'/verify-account-success'} />;
  }

  if (errorMessage) {
    return <Redirect push to={{ pathname: '/verify-account-error', state: { token, errorMessage } }} />;
  }

  return (
    <SignInLayout pageHeader={I18n.t('account_verification')}>
      <p className='m-bottom--28 color--grey600'>
        {I18n.t('verifying_your_account')}...
      </p>
    </SignInLayout>
  );
};

export default VerifyAccount;