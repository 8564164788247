import React, { Fragment, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
  CircleLoader,
  PrimaryStretchedButton,
  TextInput,
  PasswordInput,
  I18n,
  passwordMeetsRequirements,
  passwordErrorsList,
  useMediaQuery,
} from 'design-system';

import PortalAPI from 'utilities/PortalAPI';

import ErrorAlert from './ErrorAlert';
import { bpSmall } from 'css/main.scss';
import { UserContext } from 'components/contexts/UserContext';

const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
const ACCOUNT_EXISTS = 'ACCOUNT_EXISTS';
const UNABLE_TO_VERIFY = 'UNABLE_TO_VERIFY';


const CreateAccount = () => {
  const userContext = useContext(UserContext);
  const [inputValues, setInputValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [inputErrors, setInputErrors] = useState({});
  const [errorAlertMessage, setErrorAlertMessage] = useState(null);
  const [accountStatus, setAccountStatus] = useState();
  const [loading, setLoading] = useState(false);
  const smallWindow = useMediaQuery(
    [`(min-width: ${bpSmall})`],
    [false],
    true
  );

  const inputNameToIsRequiredMessage = {
    firstName: I18n.t('first_name_is_required'),
    lastName: I18n.t('last_name_is_required'),
    email: I18n.t('email_address_is_required'),
    password: I18n.t('password_is_required'),
    confirmPassword: I18n.t('confirm_password_is_required'),
  };

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      submitCreateAccount();
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    errorAlertMessage && setErrorAlertMessage('');
    const inputErrorsToClear = { [name]: '' };
    if (name === 'password') {
      inputErrorsToClear.confirmPassword = '';
    }
    setInputErrors((prevInputErrors) => ({
      ...prevInputErrors,
      ...inputErrorsToClear,
    }));
    setInputValues((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  }

  function validateInput(name, value) {
    const newInputErrors = {};
    if (!value) {
      newInputErrors[name] = inputNameToIsRequiredMessage[name];
    } else if (name === 'password') {
      if (!passwordMeetsRequirements(value)) {
        newInputErrors.password = passwordErrorsList(value);
      }
      if (inputValues.confirmPassword) {
        if (value !== inputValues.confirmPassword) {
          newInputErrors.confirmPassword = I18n.t('confirm_password_does_not_match_password');
        } else {
          newInputErrors.confirmPassword = null;
        }
      }
    } else if (name === 'confirmPassword') {
      if (value !== inputValues.password) {
      newInputErrors.confirmPassword = I18n.t('confirm_password_does_not_match_password');
      } else {
        newInputErrors.confirmPassword = null;
      }
    }
    setInputErrors((prevInputErrors) => ({
      ...prevInputErrors,
      ...newInputErrors,
    }));
  }

  function validateAllInputs() {
    for (const name in inputValues) {
      validateInput(name, inputValues[name]);
    }
  }

  function someInputsAreBlank() {
    for (const name in inputValues) {
      if (inputValues[name] === '') {
        return true;
      }
    }
    return false;
  }

  function inputIsValid() {
    for (const key in inputValues) {
      if (inputValues[key] === '') {
        return false;
      }
    }
    if (inputValues.password !== inputValues.confirmPassword || !passwordMeetsRequirements(inputValues.password)) {
      return false;
    }
    return true;
  }

  function disableSubmitAccount() {
    return (
      loading ||
      !inputIsValid()
    );
  }

  async function submitCreateAccount() {
    if (!userContext.customerId) {
      setAccountStatus(UNABLE_TO_VERIFY);
      return;
    }
    validateAllInputs();
    if (disableSubmitAccount()) {
      return;
    }

    setErrorAlertMessage(null);
    setLoading(true);
    const data = await PortalAPI.post('auth/create_account/', {
      customer_id: userContext.customerId,
      email: inputValues.email,
      password: inputValues.password,
      first_name: inputValues.firstName,
      last_name: inputValues.lastName,
    });
    setLoading(false);
    if (data.success) {
      setAccountStatus(ACCOUNT_CREATED);
    } else if (data.error && data.error.code) {
      const { code } = data.error;
      if (code === 'UsernameExistsException') {
        setAccountStatus(ACCOUNT_EXISTS);
      } else if (code === 'GuardianException') {
        setAccountStatus(UNABLE_TO_VERIFY);
      } else if (code === 'CodeDeliveryFailureException') {
        setErrorAlertMessage(I18n.t('your_account_was_created_but_there_was_an_error_when_sending_your_confirmation_email'));
      } else {
        setErrorAlertMessage(I18n.t('unexpected_error'));
      }
    } else {
      setErrorAlertMessage(I18n.t('unexpected_error'));
    }
  }

  if (accountStatus === ACCOUNT_CREATED) {
    return <Redirect push to={{ pathname: '/create-account-confirmation/', state: { email: inputValues.email } }} />;
  }
  if (accountStatus === ACCOUNT_EXISTS) {
    return <Redirect push to={{ pathname: '/existing-account/', state: { email: inputValues.email } }} />;
  }
  if (accountStatus === UNABLE_TO_VERIFY) {
    return <Redirect push to={{ pathname: '/unable-to-verify/' }} />;
  }

  return (
    <Fragment>
      <p className='m-bottom--24'>{I18n.t('lets_get_you_signed_up')}</p>

      <div className='l-flex l-flex--column'>
        <div className='l-grid l-grid--2col m-bottom--16'>
          <TextInput
            placeholder={I18n.t('first_name')}
            name={'firstName'}
            value={inputValues.firstName}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            onBlur={(e) => validateInput(e.target.name, e.target.value)}
            errorMessage={inputErrors.firstName}
          />
          <TextInput
            placeholder={I18n.t('last_name')}
            name={'lastName'}
            value={inputValues.lastName}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            onBlur={(e) => validateInput(e.target.name, e.target.value)}
            errorMessage={inputErrors.lastName}
          />
        </div>

        <TextInput
          placeholder={I18n.t('email_address')}
          name={'email'}
          value={inputValues.email}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onBlur={(e) => validateInput(e.target.name, e.target.value)}
          errorMessage={inputErrors.email}
          containerClasses='m-bottom--16'
        />
        <PasswordInput
          useDefaultTooltip={true}
          defaultTooltipPosition={smallWindow ? 'above' : 'right' }
          placeholder={I18n.t('password')}
          name={'password'}
          value={inputValues.password}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onBlur={(e) => validateInput(e.target.name, e.target.value)}
          errorMessage={inputErrors.password}
          containerClasses='m-bottom--16'
        />
        <PasswordInput
          isConfirm={true}
          placeholder={I18n.t('confirm_password')}
          name={'confirmPassword'}
          value={inputValues.confirmPassword}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onBlur={(e) => validateInput(e.target.name, e.target.value)}
          errorMessage={inputErrors.confirmPassword}
          containerClasses='m-bottom--16'
        />
      </div>

      <div className='textAlign--center'>
        <PrimaryStretchedButton
          className='m-bottom--16'
          onClick={submitCreateAccount}
          disabled={someInputsAreBlank()}
        >
          {I18n.t('create_your_account')}
        </PrimaryStretchedButton>
      </div>

      {loading &&
        <div className='l-flex l-flex--hAlignCenter'>
          <CircleLoader />
        </div>
      }

      {errorAlertMessage &&
        <ErrorAlert className='m-bottom--16'>
          {errorAlertMessage}
        </ErrorAlert>
      }

      <p>{I18n.t('by_clicking_create_your_account_you_agree')}</p>
    </Fragment>
  );
};

export default CreateAccount;
