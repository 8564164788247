import React from 'react';
import PropTypes from 'prop-types';

import {
  useMediaQuery,
  I18n,
} from 'design-system';

import {
  bpSmall,
  bpMedium,
  bpLarge,
  bpXlarge,
} from 'css/main.scss';

import styles from './SignIn.module.scss';
import Icon from 'utilities/Icon';
import { ReactComponent as Foreground } from '../../../images/signin-foreground.svg';
import { ReactComponent as Background } from '../../../images/signin-background.svg';
import { ReactComponent as Background768 } from '../../../images/signin-background-768.svg';
import { ReactComponent as Background992 } from '../../../images/signin-background-992.svg';
import { ReactComponent as Background1280 } from '../../../images/signin-background-1280.svg';

const SignInLayout = ({
  pageHeader = I18n.t('relay_portal'),
  children,
}) => {
  const bp = useMediaQuery(
    [`(min-width: ${bpXlarge})`, `(min-width: ${bpLarge})`, `(min-width: ${bpMedium})`, `(min-width: ${bpSmall})`],
    ['xlarge', 'large', 'medium', 'small'],
    'xsmall'
  );
  const backgrounds = {
    small: <Background className={styles.backgroundImage} preserveAspectRatio='xMinYMin slice'/>,
    medium: <Background768 className={styles.backgroundImage} preserveAspectRatio='xMaxYMin slice'/>,
    large: <Background992 className={styles.backgroundImage} preserveAspectRatio='xMaxYMin slice'/>,
    xlarge: <Background1280 className={styles.backgroundImage} preserveAspectRatio='xMaxYMin slice'/>,
  };

  return (
    <main className={styles.container}>
      <div className={styles.wrapper}>
        <section className={styles.content}>
          <header>
            <Icon name='parent-portal-logo' classes='m-vertical--56' />
            <h1 className='m-bottom--40 font-size--h2'>{pageHeader}</h1>
          </header>

          {children}
        </section>

        <section className={styles.background}>
          {backgrounds[bp]}
          <Foreground className={styles.foregroundImage} preserveAspectRatio='xMidYMin slice'/>
          <p className={styles.backgroundText}>
            {I18n.t('support_your_students_learning_with_online_activity_reports')}
          </p>
        </section>
      </div>
    </main>
  );
};

SignInLayout.propTypes = {
  pageHeader: PropTypes.string,
  children: PropTypes.any,
};

export default SignInLayout;