import React from 'react';

import { PrimaryStretchedButton, I18n } from 'design-system';

import SignInLayout from './SignInLayout';


const UnableToVerify = () => {
  return (
    <SignInLayout>
      <h2 className='font-size--medium m-vertical--4'>
        {I18n.t('unable_to_verify')}
      </h2>
      <p className='m-bottom--28 color--grey600'>
        {I18n.t('we_were_unable_to_find_a_match_for_the_information')}
      </p>

      <div className='textAlign--center'>
        <PrimaryStretchedButton className='m-bottom--16' to='/'>
          {I18n.t('back_to_log_in')}
        </PrimaryStretchedButton>
      </div>
    </SignInLayout>
  );
};

export default UnableToVerify;