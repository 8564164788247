import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import {
  CircleLoader,
  PrimaryStretchedButton,
  PasswordInput,
  I18n,
  passwordMeetsRequirements,
  passwordErrorsList,
  useMediaQuery,
} from 'design-system';

import ErrorAlert from './ErrorAlert';

import PortalAPI from 'utilities/PortalAPI';

import SignInLayout from './SignInLayout';
import { bpSmall } from 'css/main.scss';


const PasswordReset = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [inputValues, setInputValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const [inputErrors, setInputErrors] = useState({});
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const smallWindow = useMediaQuery(
    [`(min-width: ${bpSmall})`],
    [false],
    true
  );

  const inputNameToIsRequiredMessage = {
    password: I18n.t('password_is_required'),
    confirmPassword: I18n.t('confirm_password_is_required'),
  };

  function inputIsValid() {
    for (const key in inputValues) {
      if (inputValues[key] === '') {
        return false;
      }
    }
    return (
      inputValues.password === inputValues.confirmPassword &&
      passwordMeetsRequirements(inputValues.password)
    );
  }

  function submitAllowed() {
    return (
      inputIsValid() &&
      !loading
    );
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      submitResetPassword();
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    errorAlertMessage && setErrorAlertMessage('');
    const inputErrorsToClear = { [name]: '' };
    if (name === 'password') {
      inputErrorsToClear.confirmPassword = '';
    }
    setInputErrors((prevInputErrors) => ({
      ...prevInputErrors,
      ...inputErrorsToClear,
    }));
    setInputValues((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  }

  function validateInput(name, value) {
    const newInputErrors = {};
    if (!value) {
      newInputErrors[name] = inputNameToIsRequiredMessage[name];
    } else if (name === 'password') {
      if (!passwordMeetsRequirements(value)) {
        newInputErrors.password = passwordErrorsList(value);
      }
      if (inputValues.confirmPassword) {
        if (value !== inputValues.confirmPassword) {
          newInputErrors.confirmPassword = I18n.t('confirm_password_does_not_match_password');
        } else {
          newInputErrors.confirmPassword = null;
        }
      }
    } else if (name === 'confirmPassword') {
      if (value !== inputValues.password) {
        newInputErrors.confirmPassword = I18n.t('confirm_password_does_not_match_password');
      } else {
        newInputErrors.confirmPassword = null;
      }
    }
    setInputErrors((prevInputErrors) => ({
      ...prevInputErrors,
      ...newInputErrors,
    }));
  }

  function validateAllInputs() {
    for (const name in inputValues) {
      validateInput(name, inputValues[name]);
    }
  }

  async function submitResetPassword() {
    validateAllInputs();
    if (!submitAllowed()) {
      return;
    }

    errorAlertMessage && setErrorAlertMessage();
    setLoading(true);
    const data = await PortalAPI.post('auth/confirm_forgot_password', {
      token: queryParams.get('token'),
      confirmation_code: queryParams.get('confirmation_code'),
      new_password: inputValues.password,
    });
    setLoading(false);
    if (data.success) {
      setPasswordResetSuccess(true);
    } else if (data.error && data.error.code === 'ExpiredCodeException') {
      setErrorAlertMessage(I18n.t('password_reset_link_expired'));
      setRedirectToLogin(true);
    } else if (data.error && data.error.code === 'CodeMismatchException') {
      setErrorAlertMessage(I18n.t('password_reset_link_invalid'));
      setRedirectToLogin(true);
    } else if (data.error && data.error.code === 'LimitExceededException') {
      setErrorAlertMessage(I18n.t('attempt_limit_exceeded_please_try_after_some_time'));
    } else {
      setErrorAlertMessage(I18n.t('unexpected_error'));
    }
  }

  if (passwordResetSuccess) {
    return <Redirect push to={'/password-reset-success'} />;
  }

  if (redirectToLogin) {
    return <Redirect push to={{ pathname: '/', state: { errorMessage: errorAlertMessage } }} />;
  }

  return (
    <SignInLayout pageHeader={I18n.t('password_reset')}>
      <div className='color--grey500 m-bottom--24'>
        {I18n.t('enter_your_new_password')}
      </div>

      <div className='l-flex l-flex--column'>
        <PasswordInput
          useDefaultTooltip={true}
          defaultTooltipPosition={smallWindow ? 'above' : 'right' }
          placeholder={I18n.t('password')}
          containerClasses='m-bottom--24'
          name={'password'}
          value={inputValues.password}
          onKeyPress={handleKeyPress}
          onChange={handleInputChange}
          onBlur={(e) => validateInput(e.target.name, e.target.value)}
          errorMessage={inputErrors.password}
        />

        <PasswordInput
          isConfirm={true}
          placeholder={I18n.t('confirm_password')}
          containerClasses='m-bottom--24'
          name={'confirmPassword'}
          value={inputValues.confirmPassword}
          onKeyPress={handleKeyPress}
          onChange={handleInputChange}
          onBlur={(e) => validateInput(e.target.name, e.target.value)}
          errorMessage={inputErrors.confirmPassword}
        />

        <div className='textAlign--center'>
          <PrimaryStretchedButton
            onClick={submitResetPassword}
            disabled={!submitAllowed()}
            className='m-bottom--16'
          >
            {I18n.t('reset_your_password')}
          </PrimaryStretchedButton>
        </div>

        {loading &&
          <div className='l-flex l-flex--hAlignCenter'>
            <CircleLoader />
          </div>
        }

        {errorAlertMessage &&
          <ErrorAlert>
            {errorAlertMessage}
          </ErrorAlert>
        }
      </div>
    </SignInLayout>
  );
};

export default PasswordReset;
