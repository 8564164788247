import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PortalAPI from 'utilities/PortalAPI';

const COGNITO_TOKEN_EXP_MS = 3600000; // 60 minutes
const REFRESH_SESSION_MS = 3300000; // 55 minutes

export const UserContext = React.createContext();
export const UserConsumer = UserContext.Consumer;
export class UserProvider extends Component {
  constructor(props) {
    super(props);
    const userData = localStorage.userData;
    this.state = {
      user: userData ? JSON.parse(userData) : undefined,
      customerId: localStorage.customerId,
      setUser: this.setUser,
      setStudents: this.setStudents,
      setCurrentStudent: this.setCurrentStudent,
      currentStudent: this.currentStudent,
      logOut: this.logOut,
      students: [],
      getCustomerId: this.getCustomerId,
    };
  }

  componentDidMount() {
    if (this.state.user && window.refreshSessionInterval === undefined) {
      if (Date.now() < parseInt(localStorage.cognitoTokenExp, 10)) {
        // token has not expired
        window.refreshSessionInterval = setInterval(this.refreshSession, REFRESH_SESSION_MS);
      } else {
        // token has expired
        this.logOut();
      }
    }

    if (this.state.user) {
      this.setUser(this.state.user);
    }

    if (!this.state.customerId) {
      this.getCustomerId();
    }
  }

  setUser = (user, accessToken, cognitoToken, refreshToken) => {
    if (accessToken && cognitoToken && refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('cognitoTokenExp', Date.now() + COGNITO_TOKEN_EXP_MS);
      localStorage.setItem('cognitoToken', cognitoToken);
      localStorage.setItem('refreshToken', refreshToken);
    } else if (!localStorage.accessToken || !localStorage.cognitoToken || !localStorage.refreshToken) {
      this.logOut();
      return;
    }
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('userEmail', user.email);
    clearInterval(window.refreshSessionInterval);
    window.refreshSessionInterval = setInterval(this.refreshSession, REFRESH_SESSION_MS);
    this.setState({ user }, () => this.getStudentNames());
  }

  async getStudentNames() {
    const results = await Promise.all(this.state.user.student_guids.map((guid) => {
      return PortalAPI.get(`students?guid=${guid}`);
    }));
    const students = results.filter((result) => {
      return Boolean(result.student);
    }).map((result) => {
      return result.student;
    });
    if (this.state.students.length === 0) {
      this.setStudents(students);
    }
  }

  setStudents = (students) => {
    let states = { students };
    if (!this.currentStudent) {
      states = { ...states, currentStudent: students[0] };
    }
    this.setState(states);
  }

  setCurrentStudent = (guid) => {
    const current = this.state.students.find((student) => student.guid === guid);
    this.setState({ currentStudent: current });
  }

  refreshSession = async () => {
    if (!localStorage.refreshToken) {
      this.logOut();
      return;
    }
    const data = await PortalAPI.post('auth/refresh_session', {
      refresh_token: localStorage.refreshToken,
    });
    const { accessToken, cognitoToken } = data;
    if (accessToken && cognitoToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('cognitoToken', cognitoToken);
      localStorage.setItem('cognitoTokenExp', Date.now() + COGNITO_TOKEN_EXP_MS);
    } else {
      this.logOut();
    }
  }

  logOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('cognitoToken');
    localStorage.removeItem('cognitoTokenExp');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userData');
    if (localStorage.rememberUser !== 'true') {
      localStorage.removeItem('rememberUser');
      localStorage.removeItem('userEmail');
    }
    clearInterval(window.refreshSessionInterval);
    this.setState({ user: undefined });
    window.location = '/';
  }

  getCustomerId = async () => {
    const hostParts = window.location.host.split('.');
    let subdomain = hostParts[0];
    const data = await PortalAPI.get(`districts/customer_id?subdomain=${subdomain}`);
    if (data && data.customerId) {
      localStorage.setItem('customerId', data.customerId);
      this.setState({
        customerId: data.customerId,
      }, () => this.getStudentNames());
    }
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

UserProvider.propTypes = {
  children: PropTypes.object,
};
