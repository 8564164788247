import React from 'react';

import { I18n, PrimaryStretchedButton } from 'design-system';

import SignInLayout from './SignInLayout';


const VerifyAccountSuccess = () => {
  return (
    <SignInLayout pageHeader={I18n.t('account_verified')}>
      <h2 className='font-size--medium m-vertical--4'>
        {I18n.t('welcome_aboard')}
      </h2>

      <p className='m-bottom--28 color--grey600'>
        {I18n.t('weve_verified_your_account_you_can_now_log_in')}
      </p>

      <div className='textAlign--center'>
        <PrimaryStretchedButton className='m-bottom--16' to='/'>
          {I18n.t('log_in')}
        </PrimaryStretchedButton>
      </div>
    </SignInLayout>
  );
};

export default VerifyAccountSuccess;