import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { Redirect } from 'react-router-dom';

import {
  Header,
  QuietSelect,
  UserMenu,
  CircleLoader,
  NextButton,
  PreviousButton,
  I18n,
  cn,
} from 'design-system';

import PortalAPI from 'utilities/PortalAPI';

import { UserContext } from 'components/contexts/UserContext';

import PauseInternet from './PauseInternet';
import ActivityTable from './ActivityTable';

import { ReactComponent as Logo } from 'images/logo-relay-portal.svg';
import styles from './InternetActivity.module.scss';


function reducer(state, action) {
  switch (action.type) {
    case 'selectStudent':
      return {
        ...state,
        isLoading: true,
        currentPage: 0,
        nextPageToken: undefined,
        pages: [],
      };
    case 'setPage':
      const newCurrentPage = state.currentPage + 1;
      const newPages = [...state.pages];
      newPages.push({ results: action.results.data, page: newCurrentPage });
      return {
        ...state,
        pages: newPages,
        isLoading: false,
        nextPageToken: action.results.nextPage,
        currentPage: newCurrentPage,
      };
    case 'setErrors':
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };
    case 'nextPage':
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case 'previousPage':
      return {
        ...state,
        currentPage: state.currentPage - 1,
      };
    case 'setDistrictData':
      return {
        ...state,
        timeZone: action.district.timeZone,
        allowParentPortalPause: action.district.allowParentPortalPause === 'true',
      };
    default:
      return state;
  };
}

const InternetActivity = () => {
  const userContext = useContext(UserContext);

  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    pages: [],
    currentPage: 0,
    nextPageToken: undefined,
  });

  const getInternetActivity = useCallback(async (nextPageToken) => {
    const queryString = nextPageToken ? `&page=${nextPageToken}` : '';
    const customerId = userContext.user && userContext.user.customer_id;
    const url = `students/${customerId}/${userContext.currentStudent.guid}/internet_activity?allow=allow${queryString}`;
    const result = await PortalAPI.get(url);
    if (result.data) {
      // ensure we wait at least 2 seconds before display results.
      // this prevents random flashes of content that could be jarring to the user.
      setTimeout(() => {
        dispatch({ type: 'setPage', results: result });
      }, 2000);
    } else if (result.error) {
      dispatch({ type: 'setErrors', error: result.error.message });
    } else {
      dispatch({ type: 'setErrors', error: I18n.t('unexpected_error') });
    }
  }, [userContext.currentStudent, userContext.user]);

  function handleNextPage() {
    if (!state.nextPageToken && state.pages.length === state.currentPage) {
      return;
    }
    if (state.nextPageToken) {
      getInternetActivity(state.nextPageToken);
    } else {
      dispatch({ type: 'nextPage' });
    }
  }

  function handlePreviousPage() {
    if (state.currentPage === 0) {
      return;
    }
    dispatch({ type: 'previousPage' });
  }

  useEffect(() => {
    if (userContext.user) {
      async function getDistrictData() {
        const result = await PortalAPI.get(`districts/${userContext.user.customer_id}`);
        if (result.district) {
          dispatch({ type: 'setDistrictData', district: result.district });
        }
      }
      getDistrictData();
    }
  }, [userContext.user]);

  useEffect(() => {
    if (userContext.currentStudent && userContext.currentStudent.guid) {
      getInternetActivity();
    }
  }, [getInternetActivity, userContext.currentStudent]);

  if (!userContext.user) {
    return <Redirect to='/' />;
  }

  const tableClasses = cn(styles.tableContent, {
    [styles.tableContentFullWidth]: !state.allowParentPortalPause,
  });

  const userName = userContext.user && `${userContext.user.first_name} ${userContext.user.last_name}`;

  return (
    <Fragment>
      <Header>
        <Logo />
        <UserMenu userName={userName} signOutHandler={() => userContext.logOut()} />
      </Header>

      <main>
        <section className={styles.hero}>
          <h1 className='m-bottom--16'>{I18n.t('student_internet_activity')}</h1>

          <p className={styles.heroText}>
            {I18n.t('one_of_the_best_ways_you_can_support_your_students')}
          </p>

          <div className={styles.studentSelectContainer}>
            <QuietSelect
              className={styles.studentSelect}
              defaultValue={userContext.currentStudent && userContext.currentStudent.guid}
              options={userContext.students.map((student) => ({
                label: student.name,
                value: student.guid,
              }))}
              onChange={(e) => {
                userContext.setCurrentStudent(e.target.value);
                dispatch({ type: 'selectStudent' });
              }}
            />
          </div>
        </section>

        <section className={styles.content}>
          {state.allowParentPortalPause &&
            <PauseInternet />
          }

          <div className={tableClasses}>
            <h2 className={styles.pastDaysLabel}>
              {I18n.t('past_7_days')}
            </h2>

            {state.isLoading &&
              <div className='l-flex l-flex--hAlignCenter'>
                <CircleLoader />
              </div>
            }

            {!state.isLoading && state.currentPage && (
              <ActivityTable
                pages={state.pages}
                currentPage={state.currentPage}
                hasNextPage={Boolean(state.nextPageToken)}
                timeZone={state.timeZone}
              />
            )}

            {!state.isLoading && state.pages[0] && state.pages[0].results.length > 0 && (
              <div className='l-flex l-flex--hAlignCenter m-top--32'>
                <PreviousButton
                  onClick={() => handlePreviousPage()}
                  disabled={state.currentPage <= 1}
                />

                <NextButton
                  onClick={() => handleNextPage()}
                  disabled={!state.nextPageToken && state.pages.length === state.currentPage}
                />
              </div>
            )}
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default InternetActivity;
