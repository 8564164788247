/* eslint-disable max-len */
export default {
  a_lowercase_letter: 'a lowercase letter',
  a_number: 'a number',
  account_verification: 'Account Verification',
  account_verified: 'Account Verified',
  address: 'Address',
  an_uppercase_letter: 'an uppercase letter',
  and_a_special_character: 'and a special character',
  at_least_8_characters_including: 'At least 8 characters including:',
  attempt_limit_exceeded_please_try_after_some_time: 'Attempt limit exceeded, please try after some time.',
  back_to_log_in: 'Back to log in',
  by_clicking_create_your_account_you_agree: 'By clicking "Create your account" you agree to the Lightspeed Systems [Terms & Conditions](https://www.lightspeedsystems.com/tc/)',
  check_back_later_after_your_student_has_browsed_the_web: 'Check back later after your student has browsed the web.',
  check_your_email: 'Check your email',
  check_your_inbox_we_just_resent_your_password_reset_link: 'Check your inbox. We just resent your password reset link.',
  check_your_inbox_we_just_resent_your_verification_link: 'Check your inbox. We just resent your verification link.',
  confirm_password: 'Confirm password',
  confirm_password_does_not_match_password: 'Confirm password does not match password',
  confirm_password_is_required: 'Confirm password is required',
  count_item: '%{count} item',
  count_items: '%{count} items',
  create_account: 'Create Account',
  create_your_account: 'Create your account',
  deactivated_account: 'Deactivated account',
  dont_see_the_email_click_here_to_resend_it: "Don't see the email? Click <here> to resend it.",
  email_address: 'Email address',
  email_address_is_required: 'Email address is required',
  email_sent: 'Email sent',
  email_verification_link_expired: 'Your email verification link is expired. Please request another one.',
  email_verification_link_invalid: 'Your email verification link is invalid. Please request another one.',
  enter_your_email_address_and_well_send_a_link_you: "Enter you email address and we'll send a link you can use to reset your password.",
  enter_your_new_password: 'Enter your new password',
  error: 'Error',
  first_name: 'First name',
  first_name_is_required: 'First name is required',
  for_1_hour: 'For 1 hour',
  for_3_hours: 'For 3 hours',
  forgot_password: 'Forgot password',
  forgot_your_password: 'Forgot your password?',
  get_help: 'Get help',
  give_feedback: 'Give feedback',
  got_it: 'Got it!',
  invalid_email_or_password: 'Invalid email or password.',
  is_required: 'is required',
  it_appears_your_account_has_been_deactivated_by_the_district_or_school_if: 'It appears your account has been deactivated by the district or school. If you believe this was a mistake, please contact them and request access to Relay Portal.',
  last_name: 'Last name',
  last_name_is_required: 'Last name is required',
  lets_get_you_signed_up: "Let's get you signed up!",
  log_in: 'Log in',
  log_out: 'Log out',
  no_account_found_for_that_email_address: 'No account found for that email address.',
  no_data_to_display: 'No data to display',
  notifications: 'Notifications',
  one_of_the_best_ways_you_can_support_your_students: "One of the best ways you can support your student's Internet-based learning is by monitoring his or her browsing. This report details browsing history and allows you to temporarily limit access.",
  password: 'Password',
  password_is_required: 'Password is required',
  password_must_contain_at_least_8_characters: 'Password must contain at least 8 characters, an uppercase letter, a lowercase letter, a number, and a special character',
  password_requirements: 'Password Requirements',
  password_reset: 'Password Reset',
  password_reset_link_expired: 'Your password reset link is expired. Please request another one.',
  password_reset_link_invalid: 'Your password reset link is invalid. Please request another one.',
  password_reset_required_please_click_forgot_password: 'Password reset required. Please click on the forgot password link below.',
  past_7_days: 'Past 7 Days',
  pause_web_browsing: 'Pause web browsing',
  paused_until_time: 'Paused until %{time}',
  privacy_policy: 'Privacy policy',
  relay_portal: 'Relay Portal',
  remember_me: 'Remember me',
  resend_verification_email: 'Resend verification email',
  reset_your_password: 'Reset your password',
  send_reset_link: 'Send reset link',
  sign_out: 'Sign out',
  site_icon_for: 'site icon for %{string}',
  student_internet_activity: 'Student Internet Activity',
  success: 'Success!',
  support_your_students_learning_with_online_activity_reports: "Support your student's learning with online activity reports",
  switch_on_to_temporary_block_website_browsing_while_not_at_school: 'Switch on to temporary block website browsing while not at school.',
  terms_of_use: 'Terms of use',
  thanks_for_signing_up_we_just_sent_a_verification_code: "Thanks for signing up! We just sent a verification link to your email. Click on it to log in and view your student's report.",
  time: 'Time',
  today: 'Today',
  unable_to_verify: 'Unable to verify',
  unexpected_error: 'An unexpected error occurred.',
  until_tomorrow_morning: 'Until tomorrow morning',
  verifying_your_account: 'Verifying your account',
  we_found_a_user_with_the_same_email_address_in_our_system_try_logging: "We found a user with the same email address in our system. Try logging in or make a request to <reset your password> if you've forgotten it.",
  we_just_sent_a_password_reset_link_to_your_email: 'We just sent a password reset link to your email. Click on the link to reset your password and log in.',
  we_were_unable_to_find_a_match_for_the_information: 'We were unable to find a match for the information you provided. Please contact your school or district and request access to Relay Portal.',
  welcome_aboard: 'Welcome aboard!',
  welcome_back_please_log_into_your_account: 'Welcome back. Please log into your account.',
  weve_verified_your_account_you_can_now_log_in: "We've verified your account. You can now login to Relay Portal and begin viewing your student's Internet activity.",
  yesterday: 'Yesterday',
  you_already_have_an_account: 'You already have an account',
  your_account_was_created_but_there_was_an_error_when_sending_your_confirmation_email: 'Your account was created but an error occurred when attempting to send an email with a verification link to your address. Please contact an administrator.',
  your_email_address_is_not_verified: 'Your email address is not verified.',
  your_password_has_been_reset_please_log_in_to_access: "Your password has been reset. Please log in to access your student's online activity report.",
};
