import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Icon = memo(({ name, classes, onClick }) => {
  const iconClasses = classNames(name, classes, 'icon');

  return (
    <svg className={iconClasses} onClick={onClick}>
      <use xlinkHref={`#${name}`}></use>
    </svg>
  );
});

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;