import React from 'react';

import { I18n, PrimaryStretchedButton } from 'design-system';

import SignInLayout from './SignInLayout';


const DeactivatedAccount = () => {
  return (
    <SignInLayout>
      <h2 className='font-size--medium m-vertical--4'>
        {I18n.t('deactivated_account')}
      </h2>

      <p className='m-bottom--28 color--grey600'>
        {I18n.t('it_appears_your_account_has_been_deactivated_by_the_district_or_school_if')}
      </p>

      <div className='textAlign--center'>
        <PrimaryStretchedButton className='m-bottom--16' to='/'>
          {I18n.t('back_to_log_in')}
        </PrimaryStretchedButton>
      </div>
    </SignInLayout>
  );
};

export default DeactivatedAccount;