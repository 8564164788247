import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  I18n,
  Tabs,
  Tab,
} from 'design-system';

import CreateAccount from './CreateAccount';
import Login from './Login';
import SignInLayout from './SignInLayout';

import { UserContext } from 'components/contexts/UserContext';


const SignIn = () => {
  const userContext = useContext(UserContext);
  let content;
  if (userContext.customerId) {
    content = (
      <Tabs containerClass='color--grey500'>
        <Tab label={I18n.t('log_in')}>
          <Login />
        </Tab>

        <Tab label={I18n.t('create_account')}>
          <CreateAccount />
        </Tab>
      </Tabs>
    );
  } else {
    content = <Login />;
  }

  return (
    <SignInLayout>
      {content}

      <div className='m-top--8'>
        <Link to='/forgot-password' className='font-size--small'>
          {I18n.t('forgot_your_password')}
        </Link>
      </div>
    </SignInLayout>
  );
};

export default SignIn;