import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './SignIn.module.scss';


const ErrorAlert = ({
  children,
  className,
}) => {
  const classes = cn(styles.signInErrorAlert, className);

  return (
    <section className={classes}>
      {children}
    </section>
  );
};

ErrorAlert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default ErrorAlert;