import { I18n } from 'design-system';

let baseUrl = process.env.REACT_APP_PORTAL_API_URL;
if (!baseUrl.endsWith('/')) {
  baseUrl += '/';
}

export default {
  async get(url) {
    return await this.request('get', url);
  },

  async post(url, data) {
    return await this.request('post', url, data);
  },

  async put(url, data) {
    return await this.request('put', url, data);
  },

  async delete(url, data) {
    return await this.request('delete', url, data);
  },

  async request(method, url, data) {
    if (url.startsWith('/')) {
      url = url.slice(1);
    }
    try {
      const response = await fetch(baseUrl + url, {
        method,
        headers: {
          'x-api-key': process.env.REACT_APP_PORTAL_API_KEY,
          'Content-Type': 'application/json',
          accessToken: localStorage.accessToken,
          cognitoToken: localStorage.cognitoToken,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      return {
        error: {
          message: (error && error.message) || I18n.t('unexpected_error'),
        },
      };
    }
  },
};
