import React from 'react';

import { PrimaryStretchedButton, I18n } from 'design-system';

import SignInLayout from './SignInLayout';


const PasswordResetSuccess = () => {
  return (
    <SignInLayout pageHeader={I18n.t('password_reset')}>
      <h2 className='font-size--medium m-vertical--4'>
        {I18n.t('success')}
      </h2>

      <p className='m-bottom--28 color--grey600'>
        {I18n.t('your_password_has_been_reset_please_log_in_to_access')}
      </p>

      <div className='textAlign--center'>
        <PrimaryStretchedButton className='m-bottom--16' to='/'>
          {I18n.t('log_in')}
        </PrimaryStretchedButton>
      </div>
    </SignInLayout>
  );
};

export default PasswordResetSuccess;