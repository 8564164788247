import React, { Fragment, useState, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import {
  CircleLoader,
  PrimaryStretchedButton,
  TextInput,
  PasswordInput,
  CheckboxWithLabel,
  I18n,
} from 'design-system';

import PortalAPI from 'utilities/PortalAPI';

import ErrorAlert from './ErrorAlert';

import { UserContext } from 'components/contexts/UserContext';


const Login = () => {
  const { state } = useLocation();
  const [inputValues, setInputValues] = useState({
    email: (localStorage.rememberUser === 'true' && localStorage.userEmail) || '',
    password: '',
  });
  const [inputErrors, setInputErrors] = useState({
    email: '',
    password: '',
  });
  const [errorAlertMessage, setErrorAlertMessage] = useState((state && state.errorMessage) || '');
  const [loading, setLoading] = useState(false);
  const [userIsDisabled, setUserIsDisabled] = useState(false);
  const [userIsNotConfirmed, setUserIsNotConfirmed] = useState(false);

  const userContext = useContext(UserContext);

  const inputNameToIsRequiredMessage = {
    email: I18n.t('email_address_is_required'),
    password: I18n.t('password_is_required'),
  };

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      submitLogIn();
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    errorAlertMessage && setErrorAlertMessage('');
    inputErrors[name] && setInputErrors((prevInputErrors) => ({
      ...prevInputErrors,
      [name]: '',
    }));
    setInputValues((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  }

  function validateInput(name, value) {
    if (!value) {
      setInputErrors((prevInputErrors) => ({
        ...prevInputErrors,
        [name]: inputNameToIsRequiredMessage[name],
      }));
    }
  }

  function validateAllInputs() {
    for (const name in inputValues) {
      validateInput(name, inputValues[name]);
    }
  }

  function someInputsAreBlank() {
    for (const name in inputValues) {
      if (inputValues[name] === '') {
        return true;
      }
    }
    return false;
  }

  function disableLogIn() {
    return (
      loading ||
      someInputsAreBlank()
    );
  }

  function handleRememberMe(e) {
    localStorage.setItem('rememberUser', e.target.checked);
  }

  async function submitLogIn() {
    validateAllInputs();
    if (disableLogIn()) {
      return;
    }

    errorAlertMessage && setErrorAlertMessage('');
    setLoading(true);
    const data = await PortalAPI.post('auth/', {
      customer_id: userContext.customerId,
      email: inputValues.email,
      password: inputValues.password,
    });
    setLoading(false);
    if (data.user) {
      userContext.setUser(data.user, data.accessToken, data.cognitoToken, data.refreshToken);
    } else {
      if (data.error && data.error.code === 'UserNotFoundException') {
        setErrorAlertMessage(I18n.t('no_account_found_for_that_email_address'));
      } else if (data.error && data.error.message === 'User is disabled.') {
        setUserIsDisabled(true);
      } else if (data.error && data.error.message === 'User is not confirmed.') {
        setUserIsNotConfirmed(true);
      } else if (data.error && data.error.message === 'Incorrect username or password.') {
        setErrorAlertMessage(I18n.t('invalid_email_or_password'));
      } else if (data.error && data.error.message === 'Password reset required for the user') {
        setErrorAlertMessage(I18n.t('password_reset_required_please_click_forgot_password'));
      } else {
        setErrorAlertMessage(I18n.t('unexpected_error'));
      }
    }
  }

  if (userContext.loading) {
    return <div>Loading user...</div>;
  }

  if (userIsDisabled) {
    return <Redirect push to='/deactivated-account' />;
  }

  if (userIsNotConfirmed) {
    return (
      <Redirect
        push to={{
          pathname: '/verify-account-error',
          state: {
            email: inputValues.email,
            errorMessage: I18n.t('your_email_address_is_not_verified'),
          },
        }}
      />
    );
  }

  if (userContext.user) {
    return <Redirect push to='/internet-activity' />;
  }

  return (
    <Fragment>
      <p className='m-bottom--24'>{I18n.t('welcome_back_please_log_into_your_account')}</p>

      <div className='l-flex l-flex--column'>
        <TextInput
          placeholder={I18n.t('email_address')}
          containerClasses='m-bottom--16'
          name={'email'}
          value={inputValues.email}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onBlur={(e) => validateInput(e.target.name, e.target.value)}
          errorMessage={inputErrors.email}
        />
        <PasswordInput
          placeholder={I18n.t('password')}
          containerClasses='m-bottom--16'
          name={'password'}
          value={inputValues.password}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onBlur={(e) => validateInput(e.target.name, e.target.value)}
          errorMessage={inputErrors.password}
        />
      </div>

      <div className='m-bottom--32'>
        <CheckboxWithLabel
          label={I18n.t('remember_me')}
          onClick={handleRememberMe}
          defaultChecked={localStorage.rememberUser === 'true'}
        />
      </div>


      <div className='textAlign--center'>
        <PrimaryStretchedButton
          className='m-bottom--16'
          onClick={submitLogIn}
          disabled={disableLogIn()}
        >
          {I18n.t('log_in')}
        </PrimaryStretchedButton>
      </div>

      {loading &&
        <div className='l-flex l-flex--hAlignCenter'>
          <CircleLoader />
        </div>
      }

      {errorAlertMessage &&
        <ErrorAlert className='m-bottom--16'>
          {errorAlertMessage}
        </ErrorAlert>
      }
    </Fragment>
  );
};

export default Login;
