import React, { useContext, useReducer } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import {
  PrimaryStretchedButton,
  EmailSentIcon,
  I18n,
  CircleLoader,
  ToastNotification,
} from 'design-system';

import PortalAPI from 'utilities/PortalAPI';

import SignInLayout from './SignInLayout';
import ErrorAlert from './ErrorAlert';

import { UserContext } from 'components/contexts/UserContext';


function reducer(state, action) {
  switch (action.type) {
    case 'addNotification':
      return {
        ...state,
        notifications: state.notifications.concat({
          id: uuidv4(),
          heading: I18n.t('email_sent'),
          message: I18n.t('check_your_inbox_we_just_resent_your_verification_link'),
        }),
      };
    case 'setErrorMessage':
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case 'setIsLoading':
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  };
}

const CreateAccountConfirmation = () => {
  const userContext = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, {
    notifications: [],
    isLoading: false,
    errorMessage: null,
  });
  const location = useLocation();
  const email = location.state && location.state.email;

  async function resendVerificationEmail() {
    state.errorMessage && dispatch({ type: 'setErrorMessage', errorMessage: null });
    dispatch({ type: 'setIsLoading', isLoading: true });
    const data = await PortalAPI.post('auth/resend_confirmation_code', {
      email,
      customer_id: userContext.customerId,
    });
    let errorMessage;
    if (data.success) {
      dispatch({ type: 'addNotification' });
    } else if (data.error && data.error.code === 'LimitExceededException') {
      errorMessage = I18n.t('attempt_limit_exceeded_please_try_after_some_time');
    } else {
      errorMessage = I18n.t('unexpected_error');
    }
    errorMessage && dispatch({ type: 'setErrorMessage', errorMessage });
    dispatch({ type: 'setIsLoading', isLoading: false });
  }

  if (!email) {
    return <Redirect to='/' />;
  }

  return (
    <SignInLayout>
      <EmailSentIcon className='icon-email-sent' />
      <h2 className='font-size--medium m-top--20 m-bottom--8'>
        {I18n.t('check_your_email')}
      </h2>
      <p className='m-bottom--28 color--grey600'>
        {I18n.t('thanks_for_signing_up_we_just_sent_a_verification_code')}
      </p>

      <div className='textAlign--center'>
        <PrimaryStretchedButton className='m-bottom--16' to='/'>
          {I18n.t('got_it')}
        </PrimaryStretchedButton>
      </div>

      <p className='color--grey600 font-size--small m-bottom--16'>
        {I18n.t('dont_see_the_email_click_here_to_resend_it', { action: resendVerificationEmail })}
      </p>

      <ToastNotification notifications={state.notifications} />

      {state.isLoading &&
        <div className='l-flex l-flex--hAlignCenter'>
          <CircleLoader />
        </div>
      }

      {state.errorMessage &&
        <ErrorAlert>
          {state.errorMessage}
        </ErrorAlert>
      }
    </SignInLayout>
  );
};


export default CreateAccountConfirmation;
