import React from 'react';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import { CeleratisConnector } from 'design-system';

import translations from '../utilities/i18n.en';
import { UserProvider } from './contexts/UserContext';

import SignIn from 'components/views/SignIn/SignIn';
import CreateAccountConfirmation from 'components/views/SignIn/CreateAccountConfirmation';
import VerifyAccount from 'components/views/SignIn/VerifyAccount';
import VerifyAccountError from 'components/views/SignIn/VerifyAccountError';
import VerifyAccountSuccess from 'components/views/SignIn/VerifyAccountSuccess';
import UnableToVerify from 'components/views/SignIn/UnableToVerify';
import ExistingAccount from 'components/views/SignIn/ExistingAccount';
import ForgotPassword from 'components/views/SignIn/ForgotPassword';
import PasswordReset from 'components/views/SignIn/PasswordReset';
import PasswordResetSuccess from 'components/views/SignIn/PasswordResetSuccess';
import PasswordResetLinkSent from 'components/views/SignIn/PasswordResetLinkSent';
import DeactivatedAccount from 'components/views/SignIn/DeactivatedAccount';
import InternetActivity from 'components/views/InternetActivity/InternetActivity';

import Debug from 'components/Debug';


// Connect translations and React Router Link to Celeratis
CeleratisConnector.init({ translations, RouterLink: Link });

const Routes = () => {
  return (
    <UserProvider>
      <BrowserRouter>
        <Switch>
          {process.env.REACT_APP_ENV !== 'production' && (
            <Route exact path='/debug' component={Debug} />
          )}

          <Route exact path='/create-account-confirmation' component={CreateAccountConfirmation} />
          <Route exact path='/unable-to-verify' component={UnableToVerify} />
          <Route exact path='/existing-account' component={ExistingAccount} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/password-reset' component={PasswordReset} />
          <Route exact path='/password-reset-sent' component={PasswordResetLinkSent} />
          <Route exact path='/password-reset-success' component={PasswordResetSuccess} />
          <Route exact path='/deactivated-account' component={DeactivatedAccount} />
          <Route exact path='/verify-account' component={VerifyAccount} />
          <Route exact path='/verify-account-error' component={VerifyAccountError} />
          <Route exact path='/verify-account-success' component={VerifyAccountSuccess} />

          <Route exact path='/internet-activity' component={InternetActivity} />


          <Route path='/' component={SignIn} />
        </Switch>
      </BrowserRouter>
    </UserProvider>
  );
};

export default Routes;
