import React from 'react';
import { useLocation } from 'react-router-dom';

import { PrimaryStretchedButton, I18n } from 'design-system';

import SignInLayout from './SignInLayout';


const ExistingAccount = () => {
  const { state } = useLocation();
  return (
    <SignInLayout>
      <h2 className='font-size--medium m-vertical--4'>
        {I18n.t('you_already_have_an_account')}
      </h2>
      <p className='m-bottom--28 color--grey600'>
        {I18n.t('we_found_a_user_with_the_same_email_address_in_our_system_try_logging', {
          to: {
            pathname: '/forgot-password',
            state: {
              email: state && state.email,
            },
          },
        })}
      </p>

      <div className='textAlign--center'>
        <PrimaryStretchedButton className='m-bottom--16' to='/'>
          {I18n.t('back_to_log_in')}
        </PrimaryStretchedButton>
      </div>
    </SignInLayout>
  );
};

export default ExistingAccount;